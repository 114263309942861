import { useNavigate } from "react-router-dom";
import useSessionStore from "../store/useSessionStore";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Game } from "../types/Game";
import { callWebToAppFunction } from "../utils/androidCallers";
import {
  EventSource,
  EventTypes,
  MODALS,
  PopupType,
  WebToApp,
} from "../constants/Constants";
import { EventInfo } from "../types/window";
import { gameSessionStart } from "../services/backend-api";
import OfflineModal from "./Modal/ModalStyles/OfflineModal";
import Backdrop from "./Backdrop";
import ErrorModal from "./Modal/ModalStyles/OfflineModal copy";
import * as Sentry from "@sentry/browser";

import useGameStore from "../store/useGameStore";

const HeroBanner: React.FC = () => {
  const navigate = useNavigate();
  const setGameSessionId = useSessionStore((state) => state.setGameSessionId);
  const homePageParameters = useSessionStore((state) => state.homePageParams);

  const [showLoader, setShowLoader] = useState(false);

  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const retryHandler = useRef<() => boolean>();
  const games = useGameStore<{ [key: string]: Game[] }>((state) => state.games);
  const game = Object.values(games)
    .flat()
    .find((game: Game) => game.name === "Cake Match3");

  useEffect(() => {
    if (currentModal) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "visible";
    }
  }, [currentModal]);

  const handleButtonClick = () => {
    // console.log("GameCards.tsx:Analytics:Calling button click analytics");

    if (game) {
      const cardClickEvent: EventInfo = {
        eventType: EventTypes.card_click,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: window.navigator.onLine ? "online" : "offline",
          card_name: game.name,
          card_id: game.id,
          list_id: game.id,
          // card_index: index + 1,
          card_type: "hero_banner",
          referrer: "Homepage",
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        cardClickEvent,
        null,
        undefined,
      );
      gameOpen();
    }
  };
  const gameOpen = () => {
    if (!window.navigator.onLine) {
      const modal_viewed_event: EventInfo = {
        eventType: EventTypes.modal_viewed,
        eventProperties: {
          web_timestamp: Date.now(),
          internetstate: window.navigator.onLine ? "online" : "offline",
          popup_type: PopupType.OFFLINE,
          source: EventSource.HOME_LOAD,
          game_name: game?.name,
          game: game?.id,
        },
      };
      callWebToAppFunction(
        WebToApp.ANALYTICS_LISTENER,
        "",
        "",
        "",
        modal_viewed_event,
        null,
        undefined,
      );
      retryHandler.current = () => {
        if (window.navigator.onLine) {
          console.log(
            "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
          );
          gameOpen();
          return true;
        }
        return false;
      };
      setCurrentModal(MODALS.OFFLINE);
    } else {
      console.log("user is online");
      setShowLoader(true);
      gameSessionStart({
        userId: homePageParameters.userId,
        gameId: game!.id,
        bundleId: homePageParameters.bundleId,
        ipAddress: homePageParameters.ipAddress,
        startTime: new Date().toISOString(),
        accountId: homePageParameters.accountId,
        gameConfigId: game!.gameConfigId,
        webviewSessionId: homePageParameters.webviewSessionId,
      })
        .then((res: { data: { gameSessionId: string } }) => {
          // console.log(
          //   "session id created time it took",
          //   performance.now() - time,
          //   "satrt tiume was:",
          //   time
          // );
          //gameSessionId = res?.data?.gameSessionId;
          setGameSessionId(res?.data?.gameSessionId);
          console.log(
            "Game session id created:",
            res?.data?.gameSessionId,
            " navigating to the game",
          );
          setShowLoader(false);
          navigate(`/game/${game?.id}`);
        })
        .catch((e) => {
          Sentry.captureException(e);
          setShowLoader(false);
          console.error("gameSessionStart error api: ", e);
          const modal_viewed_event: EventInfo = {
            eventType: EventTypes.modal_viewed,
            eventProperties: {
              web_timestamp: Date.now(),
              internetstate: window.navigator.onLine ? "online" : "offline",
              popup_type: PopupType.UNEXPECTED_ERROR,
              source: EventSource.HOME_LOAD,
              game_name: game?.name,
              game_id: game?.id,
            },
          };
          callWebToAppFunction(
            WebToApp.ANALYTICS_LISTENER,
            "",
            "",
            "",
            modal_viewed_event,
            null,
            undefined,
          );
          retryHandler.current = () => {
            if (window.navigator.onLine) {
              console.log(
                "I am here with onRetryClickedHandler USER_OFFLINE_REPLAY",
              );
              gameOpen();
              return true;
            }
            return false;
          };
          setCurrentModal(MODALS.UNEXPECTED_ERROR);
        });
    }
  };
  return (
    <>
      {showLoader && (
        <Backdrop show={true}>
          <div className="spinner"></div>
        </Backdrop>
      )}
      {game && (
        <div className="flex flex-col bg-gray-100 p-4 pt-4 pb-0">
          <a onClick={handleButtonClick}>
            <img src="banner.webp" className="w-full rounded-lg" />
          </a>
        </div>
      )}
      {currentModal == "OFFLINE" && (
        <OfflineModal
          open={currentModal == "OFFLINE"}
          onRetryClickedHandler={() => {
            if (retryHandler.current && retryHandler.current()) {
              setCurrentModal(MODALS.NONE);
            }
          }}
          exitShow
        ></OfflineModal>
      )}
      {currentModal == "UNEXPECTED_ERROR" && (
        <ErrorModal
          open={currentModal == "UNEXPECTED_ERROR"}
          onRetryClickedHandler={() => {
            if (retryHandler.current && retryHandler.current()) {
              setCurrentModal(MODALS.NONE);
            }
          }}
          exitShow
        ></ErrorModal>
      )}
    </>
  );
};
export default HeroBanner;
