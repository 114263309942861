import axios from "axios";
//import axiosRetry from "axios-retry";
import {
  HomeRequest,
  HomeResponse,
  SessionEndRequest,
  SessionEndResponse,
  SessionStartRequest,
  SessionStartResponse,
} from "../types/BackendModels";

import * as Sentry from "@sentry/browser";

import { callWebToAppFunction } from "../utils/androidCallers";
import { EventTypes, WebToApp } from "../constants/Constants";
import useSessionStore from "../store/useSessionStore";
import { EventInfo } from "../types/window";
const { gameSessionId } = useSessionStore.getState();
const getInstance = async () => {
  const instance = axios.create({
    baseURL: "/api",
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 10000,
  });

  // axiosRetry(instance, {
  //   retries: 3,
  //   retryDelay: axiosRetry.exponentialDelay,
  //   shouldResetTimeout: true,
  //   retryCondition: (_error) => true,
  // });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );

  return instance;
};

export const getHomeScreen = async (data: HomeRequest) => {
  try {
    const axiosInstance = await getInstance();
    const response = await axiosInstance.post<HomeResponse>("homeScreen", data);
    console.log("data after the call:", response);
    if (response.status !== 200) {
      Sentry.captureMessage(
        `getHomeScreen response: {status: ${response.status}, message: ${response.data}} `,
        "fatal",
      );
    }
    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed
    //console.log("error in gameSessionEnd api send analytics");
    const gaetHomeScreenErrEvent: EventInfo = {
      eventType: EventTypes.api_status,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: window.navigator.onLine ? "online" : "offline",
        api_url: "homeScreen",
        response: error,
        game_session_id: gameSessionId,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      gaetHomeScreenErrEvent,
      null,
      undefined,
    );
    console.error("Error fetching home screen:", error);
    throw error;
  }
};
export const gameSessionStart = async (data: SessionStartRequest) => {
  try {
    const axiosInstance = await getInstance();
    const response = await axiosInstance.post<SessionStartResponse>(
      "gameSession/start",
      data,
    );
    if (response.status !== 200) {
      Sentry.captureMessage(
        `gameSessionStart response: {status: ${response.status}, message: ${response.data}} `,
        "fatal",
      );
    }
    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed
    //console.log("error in gameSessionStart api send analytics");
    const gameSessionStartApiErrEvent: EventInfo = {
      eventType: EventTypes.api_status,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: window.navigator.onLine ? "online" : "offline",
        api_url: "gameSession/start",
        response: error,
        game_session_id: gameSessionId,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      gameSessionStartApiErrEvent,
      null,
      undefined,
    );
    console.error("Error starting game session:", error);
    throw error;
  }
};

export const gameSessionEnd = async (data: SessionEndRequest) => {
  try {
    const axiosInstance = await getInstance();
    const response = await axiosInstance.post<SessionEndResponse>(
      "gameSession/end",
      data,
    );
    if (response.status !== 200) {
      Sentry.captureMessage(
        `gameSessionEnd response: {status: ${response.status}, message: ${response.data}} `,
        "fatal",
      );
    }
    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed
    //console.log("error in gameSessionEnd api send analytics");
    const gameSessionEndApiErrEvent: EventInfo = {
      eventType: EventTypes.api_status,
      eventProperties: {
        web_timestamp: Date.now(),
        internetstate: window.navigator.onLine ? "online" : "offline",
        api_url: "gameSession/end",
        response: error,
        game_session_id: gameSessionId,
      },
    };
    callWebToAppFunction(
      WebToApp.ANALYTICS_LISTENER,
      "",
      "",
      "",
      gameSessionEndApiErrEvent,
      null,
      undefined,
    );
    console.error("Error ending game session:", error);
    throw error;
  }
};

export const getEnv = async () => {
  try {
    const axiosInstance = await getInstance();
    const response = await axiosInstance.get<{ [key: string]: string }>("/env");
    console.log("data after the call:", response);

    return response; // This will include the response data, status, and other information
  } catch (error) {
    // Handle or throw the error as needed

    console.error("Error fetching env:", error);
    throw error;
  }
};
