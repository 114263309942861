import { create } from "zustand";

type EnvState = {
  env: string;
  sentryDsn: string;
  setEnv: (env: string) => void;
  setSentryDsn: (sentryDsn: string) => void;
};

const useEnvStore = create<EnvState>()((set, get) => ({
  env: "",
  sentryDsn: "",
  setEnv: (env) => set({ env }),
  setSentryDsn: (sentryDsn) => set({ sentryDsn }),
}));

export default useEnvStore;
